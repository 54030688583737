import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { VendorType } from '../appConstant'

export default function FilterBox(props) {
    const accountsOption = props?.accounts?.map((item) => {
        return {
            key: item?.key,
            text: item?.text,
            value: item?.accountName
        }
    })
    return (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: "10px", marginTop: "60px" }}><h3>Filters:</h3></div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <label style={{ fontWeight: "bold" }}>Trusted Vendor Type</label>
                    <Dropdown
                        name={'vendor-type'}
                        placeholder='Select type'
                        selection
                        clearable
                        options={VendorType}
                        onChange={(e, d) => {
                            if(d?.value?.toLowerCase()?.includes("global")){
                                props?.setSelectedAccount("")
                            }
                            props?.setSelectedType(d.value)
                        }}
                        value={props?.selectedType}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <label style={{ fontWeight: "bold" }}>Trusted Vendor Name </label>
                    <Dropdown
                        name={'vendor-name'}
                        placeholder='Select or search vendor'
                        selection
                        search
                        clearable
                        options={props?.vendorsOption}
                        value={props?.vendorName}
                        onChange={(e, v) => props?.setVendorName(v?.value)}
                    />
                </div>
                {!props?.selectedType?.toLowerCase()?.includes("global") ? (
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <label style={{ fontWeight: "bold" }}>Verisk Accounts</label>
                        <Dropdown
                            name={'verisk-account'}
                            placeholder='Select or search account'
                            selection
                            search
                            clearable
                            options={accountsOption?.filter(_item => {
                                return props?.copyMetadata?.some((value) => value?.Associations?.includes(_item?.value))
                            })}
                            value={props?.selectedAccount}
                            onChange={(e, d) => {
                                props?.setSelectedAccount(d.value)
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    )
}
