import axios from "axios";
import config from "../../../../Config/Config";
import { commercialCloud } from "../../../../Config/appConstant";

const getAccountLists = (token,cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}account/account-list`, {
        headers: {
            'Authorization': token
        }
    }).then((response) => {
        resolve(response)
    }).catch(error => {
        reject(error)
    })
})

const getMetadataLists = (token,query,cloud) => new Promise((resolve, reject) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    axios.get(`${url}metadata/trusted-partner?MetadataKey=${query}`,
        {
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
})

const getAccountDetail = (token,payload,cloud)=> new Promise((reject,resolve) => {
    let url = commercialCloud === cloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
    const {table,key,value} = payload
    axios.get(`${url}query?Table=${table}&Key=${key}&Value=${value}`,{headers:{
        'Authorization': token
    }})
    .then(res=>{
        resolve(res)
    }).catch(err=>{
        reject(err)
    })
})

export { 
    getAccountLists,
    getMetadataLists,  
    getAccountDetail 
}