import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { connect} from "react-redux";
import {Segment,Label} from 'semantic-ui-react';

import HeaderComp from './Components/Header/HeaderComp';
import WelcomePage from './Components/MainPage/WelcomePage';
import ConfigRuleFormContainer from './Components/Forms/ConfigRule/Create/ConfigRuleFormContainer';
import ConfigRuleSearchContainer from './Components/Forms/ConfigRule/Update/ConfigRuleSearchContainer';
import ConfigAccountAddDecomFormContainer from './Components/Forms/ConfigAccoutMaster/AddDecommission/ConfigAccountAddDecomFormContainer';
import ResourceExceptionFormContainer from './Components/Forms/ConfigResourceException/Create/ResourceExceptionFormContainer';
import ResourceExceptionSearchContainer from './Components/Forms/ConfigResourceException/Search/ResourceExceptionSearchContainer';
import ConfigAccountSearchContainer from './Components/Forms/ConfigAccoutMaster/Search/ConfigAccountSearchContainer';
import MetaDataFormContainer from './Components/Forms/MetaData/MetaDataFormContainer';
import CheckSumGeneratorContainer from './Components/Forms/Utility/CheckSumGeneratorContainer';
import GlobalExceptionFormContainer from './Components/Forms/GlobalException/Create/GlobalExceptionFormContainer';
import ExceptionReport from './Components/Forms/ConfigReport/Exception/ExceptionReport';
import AutoRemediationAccount from './Components/Forms/ConfigReport/AutoRemediationAccount/AutoRemediationAccount';
import AutoRemediationConfigRule from './Components/Forms/ConfigReport/AutoRemediationConfigRule/AutoRemediationConfigRule';
import TrustedVendorLayout from './Components/Forms/ConfigAccoutMaster/TrustedVendor';
import TrustedPartnerLayout from './Components/Forms/ConfigAccoutMaster/TrustedPartner';
import OktaReport from './Components/Forms/AWSOktaReport';
import VendorReport from './Components/Forms/ConfigReport/TrustedVendor';
import PartnerReport from './Components/Forms/ConfigReport/TrustedPartner/PartnerReport';
class Home extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          pageSelected: ''
        };
      }

      // handleClick = (data) => {
      //   //console.log(data)
      //   this.setState({ activePage : data})
      //   history.push(data)
      // }
      // componentDidUpdate(prevProps){
      //   if (this.props.pageSelector !== prevProps.pageSelector) {
      //     //console.log('Hi')
      //     this.setState({ pageSelected : this.props.pageSelector})
      //   }
      // }
    render(){
        return(
          <React.Fragment>
              <Router>
                <HeaderComp username={this.props.username} {...this.props} logout={this.props.logout}/>
                <Switch>
                    <Route path='/' exact render={(props)=>(<WelcomePage {...this.props}/>)}/>
                    {this.props.groupName === 'SuperAdmin' && <Route path='/configrulecreate' exact render={(props)=>(<ConfigRuleFormContainer {...this.props} groupName={this.props.groupName}/>)}/>}
                    {this.props.groupName === 'SuperAdmin' && <Route path='/configrulesearch' exact render={(props)=>(<ConfigRuleSearchContainer {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' ) && <Route path='/configaccountadddecom' exact render={(props)=>(<ConfigAccountAddDecomFormContainer {...this.props}/>)} />}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps') && <Route path='/configaccountSearch' exact render={(props) => (<ConfigAccountSearchContainer {...this.props}/>)} />}
                    {(this.props.groupName === 'SuperAdmin' ) && <Route path='/globalexceptioncreate' exact render={(props)=>(<GlobalExceptionFormContainer {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' ) && <Route path='/globalexceptionupdate' exact render={(props)=>(<GlobalExceptionFormContainer {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps') && <Route path='/resourceexceptioncreate' exact render={(props)=>(<ResourceExceptionFormContainer {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps') && <Route path='/resourceexceptionsearch' exact render={(props)=>(<ResourceExceptionSearchContainer {...this.props}/>)}/>}
                    {this.props.groupName === 'SuperAdmin' && <Route path='/metadatacreate' exact render={(props)=>(<MetaDataFormContainer {...this.props}/>)}/>}
                    {this.props.groupName === 'SuperAdmin' && <Route path='/metadataupdate' exact render={(props)=>(<MetaDataFormContainer {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps') && <Route path='/utility' exact render={(props)=>(<CheckSumGeneratorContainer {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps' || this.props.groupName === 'ReadOnly') && <Route path='/account-report' exact render={(props)=>(<ExceptionReport {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps' || this.props.groupName === 'ReadOnly') && <Route path='/auto-remediation-config-rule-report' exact render={(props)=>(<AutoRemediationConfigRule {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps' || this.props.groupName === 'ReadOnly') && <Route path='/auto-remediation-account-report' exact render={(props)=>(<AutoRemediationAccount {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps' || this.props.groupName === 'ReadOnly') && <Route path='/vendor-report' exact render={(props)=>(<VendorReport {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps' || this.props.groupName === 'ReadOnly') && <Route path='/partner-report' exact render={(props)=>(<PartnerReport {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin') && <Route path='/trusted-vendor'  render={(props)=>(<TrustedVendorLayout  {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin') && <Route path='/trusted-partner'  render={(props)=>(<TrustedPartnerLayout  {...this.props}/>)}/>}
                    {(this.props.groupName === 'SuperAdmin' || this.props.groupName === 'CloudOps') && <Route path='/aws-okta-report'  render={(props)=>(<OktaReport  {...this.props}/>)}/>}
                </Switch>
                <Segment
                    textAlign='left'
                    basic
                    as="h2"
                    style={{bottom:'10px',right:'10px'}}
                  >
                    <Label>
                    <a href='https://epm.verisk.com/confluence/display/XWECLD/ConfigV2+Exception+Management+Portal+Revision' target='_blank'>{process.env.REACT_APP_RELEASE_VERSION}</a>
                    </Label>
                  </Segment>
            </Router>
        </React.Fragment>
        );
    }
}

const mapStateToProps =(state)=>{
  return {
      pageSelector : state.mainPageSelector.pageToBeLoaded
  }
  }


export default connect(mapStateToProps)(Home);

// component={ConfigAccountSearchContainer}/>